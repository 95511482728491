import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NgForOf, NgIf } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { CustomErrorStateMatcher } from '@payment-app/core/matchers/custom-error-state-matcher';
import { documentTypes } from '@payment-app/core/models/document-type';
import { PaymentIconComponent } from '@payment-app/components/icons/payment-icon';
import { CvvHelpTextDialogService } from './components/cvv-help-text-dialog/cvv-help-text-dialog.service';
import { CardForm } from './card-form.interface';

@Component({
  selector: 'card-form',
  standalone: true,
  imports: [
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    NgIf,
    NgForOf,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    PaymentIconComponent,
  ],
  templateUrl: './card-form.component.html',
  styleUrls: ['./card-form.component.scss'],
})
export class CardFormComponent implements OnInit {
  @Input() cardFormGroup!: FormGroup<CardForm>;
  @Input() withForm = true;
  @Output() saveCard = new EventEmitter<boolean>();
  @Output() isCreditCard = new EventEmitter<boolean>();
  errorMatcher = new CustomErrorStateMatcher();
  dialogService = inject(CvvHelpTextDialogService);
  creditOrDebitController = new FormControl(true);
  saveCardController = new FormControl(true);
  documentTypes = documentTypes;
  years = Array.from({ length: 26 }, (_, i) => new Date().getFullYear() + i);
  installments = Array.from({ length: 36 }, (_, i) => i + 1);

  ngOnInit(): void {
    this.saveCardControllerListener();
    this.creditOrDebitControllerListener();
    this.isCreditCard.emit(true);
  }

  getControl(name: keyof CardForm) {
    return this.cardFormGroup.get(name) as FormControl;
  }

  private saveCardControllerListener() {
    this.saveCardController.valueChanges.subscribe((value) => {
      this.saveCard.emit(value);
    });
  }

  private creditOrDebitControllerListener() {
    this.creditOrDebitController.valueChanges.subscribe((value) => {
      this.isCreditCard.emit(value);
    });
  }

  onShowPopupCvv() {
    this.dialogService.open();
  }
}
