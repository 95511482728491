import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';

import { documentTypes } from '@payment-app/core/models/document-type';
import { customerTypes } from '@payment-app/core/models/customer-type';
import { CustomErrorStateMatcher } from '@payment-app/core/matchers/custom-error-state-matcher';
import { BankTransactionForm } from './bank-transaction-form.interface';
import { PaymentIconComponent } from '@payment-app/components/icons/payment-icon';
import { TransactionDataService } from '@payment-app/core/services/transaction-data';

@Component({
  selector: 'bank-transaction-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatSelectModule,
    MatIconModule,
    PaymentIconComponent,
  ],
  templateUrl: './bank-transaction-form.component.html',
  styleUrls: ['./bank-transaction-form.component.scss'],
})
export class BankTransactionFormComponent {
  @Input() bankTransactionFormGroup!: FormGroup<BankTransactionForm>;
  readonly #transactionDataService = inject(TransactionDataService);
  protected readonly errorMatcher = new CustomErrorStateMatcher();
  protected readonly documentTypes = documentTypes;
  protected readonly customerTypes = customerTypes;
  banks = this.#transactionDataService.transactionData.portal.configuration.banks;

  getControl(name: keyof BankTransactionForm) {
    return this.bankTransactionFormGroup.get(name) as FormControl;
  }
}
