import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PaymentConfigModel } from 'src/app/core/models/payment-config.model';
import { SessionStorageService } from '../../../../core/services/session-storage.service';
import { LoginModel } from '../../../../core/models/login.model';
import { NequiResponse, NequiService, NequiStatus } from 'src/app/core/services/nequi.service';
import { AuthModel } from 'src/app/core/models/auth.model';
import { ChangeStatusService } from 'src/app/core/services/change-status.service';
import { Router } from "@angular/router";
import { LoadingScreenService } from 'src/app/core/services/loading-screen.service';
import { EncryptService } from "src/app/core/services/encrypt.service";
import { nequiCodeErrorMessage } from "./nequiCodeErrorMessage";
import { AuthService } from 'src/app/core/services/auth.service';
import { DatalayerService } from 'src/app/core/services/datalayer.service';
import { NumberSymbol } from '@angular/common';


@Component({
  selector: 'app-pay-nequi',
  templateUrl: './pay-nequi.component.html',
  styleUrls: ['./pay-nequi.component.scss']
})
export class PayNequiComponent implements OnInit {
  [x: string]: any;

  @Input() paymentRequest: PaymentConfigModel;
  auth;

  panelOpenState = false;
  activeQR = false;
  activePushNotification = false;
  activeEmbeddedExperience = false;
  tokenNequi: string = '';
  qrCodeType = 'img';
  qrCodeValue: string = '';
  activeConfirmButton = true;
  auditError: any;
  nequiPhoneNumber: string;
  timePN: string;
  timerUpdate:any;
  currentDate = new Date();
  targetTime:any;
  nequiMethod:string;

  showMessageErrorQR = false;
  showPendingMessageQR = false;
  showTimeoutMessageQR = false;
  errorMessage: string = '';
  disableUntilResponse = false;
  messageTime = false;
  pushError = false;
  QrError = false;
  showButtonPushNotification=true;
  pendingNequiPayment: boolean = false;

  showMessage = false;
  pendingMessageQR = "Esta transacción aun esta pendiente, intente de nuevo mas tarde";
  timeoutMessageQR = "Esta transacción ya caducó, se generará un nuevo código QR";
  messageErrorQR = "No se pudo general el código QR, intente nuevamente ó seleccione otro método de pago";
  noCodeMessage = "Parece que aún no has realizado tu pago";

  constructor(
    private router: Router,
    private nequiService: NequiService,
    private sessionStorageService: SessionStorageService,
    private changeStatusService: ChangeStatusService,
    private loadingScreenService: LoadingScreenService,
    private encryptService: EncryptService,
    private authService: AuthService,
    private datalayerService: DatalayerService
  ) {
    this.auth = this.sessionStorageService.getItem<LoginModel>(SessionStorageService.AUTH);

  }

  ngOnInit(): void {
    this.nequiPhoneNumber = this.paymentRequest.data.phone;
    this.isActiveEE();
    this.nequiService.nequiPendingEmitter.subscribe(res => {
      this.pendingNequiPayment = res;
      if(this.pendingNequiPayment){
        this.countDown()
        this.showButtonPushNotification=false
        this.messageTime=true
      }
    })

  }

  getQrCode() {
    this.consultQrTransactionPaymentez();
  }

  //pushNotificationNequi
  pushNotification() {
    this.activeConfirmButton = true;
  }

  //getTokenNequi
  async getTokenNequi() {
    let tokenNequi = '';
    const token = 'Bearer ' + this.auth.token;

    try {
      tokenNequi = this.sessionStorageService.getItem(SessionStorageService.NEQUI_TOKEN);

      if (!tokenNequi) {
        const subclientNequi = this.paymentRequest.data.subclient ?? null;
        const response = await this.nequiService.getToken(this.paymentRequest.data.reference, subclientNequi, token).toPromise();

        tokenNequi = this.authService.decryptDataPlain(response.token);
        this.sessionStorageService.setItem(SessionStorageService.NEQUI_TOKEN, tokenNequi);
      }
      this.activeConfirmButton = false;

      return tokenNequi;
    }

    catch (error) {
      this.errorMessage = error.error.errorMessage;
      this.loadingScreenService.stopLoading();
      this.pushError = true;
      this.showMessageError(this.errorMessage, 15000);
    }
  }

  //generateNewCodeQr
  async generateNewCodeQr(message?): Promise<void> {
    this.qrCodeValue = '';
    this.QrError = false;
    this.showMessageErrorQR = false;
    this.datalayerService.checkoutEvent('7', this.paymentRequest);
    try {
      const token = 'Bearer ' + this.auth.token;
      const nequiToken = await this.getTokenNequi();
      const request = this.encryptService.encryptCipherData({
        phone: this.nequiPhoneNumber,
        reference: this.paymentRequest.data.reference,
        amount: this.paymentRequest.data.amount,
        payment_method: message,
        payment_reference: this.paymentRequest.data.payment_reference,
        tokenNequi: nequiToken,
        subclient: this.paymentRequest.data.subclient ?? null
      });


      const newQrCode = await this.nequiService.generateNequiQR(request, token).toPromise();
      this.qrCodeValue = newQrCode['response']['qrCode'];

      if (this.qrCodeValue == '') {
        this.QrError = true;
        this.showMessageError(this.pendingMessageQR, 15000);
      }

      const auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
      const body = {
        dev_reference: this.paymentRequest.data.reference ? this.paymentRequest.data.reference : null
      }
      // await this.changeStatusService.changeStatus(auth, this.paymentRequest, message, body);
    } catch (error) {
      this.showMessageErrorQR = true;
      this.errorMessage = error.error.errorMessage;
      this.QrError = true;
      this.showMessageError(this.errorMessage, 15000);
    }

  }

  //unregisteredPayment
  async unregisteredPayment(message?): Promise<void> {

    try {
      this.pushError = false;
      this.QrError = false;
      const token = 'Bearer ' + this.auth.token;
      this.loadingScreenService.startLoading();
      this.showButtonPushNotification=false;
      const nequiToken = await this.getTokenNequi();
      this.disableUntilResponse = true;
      const request = this.encryptService.encryptCipherData({
        phone: this.nequiPhoneNumber,
        amount: this.paymentRequest.data.amount,
        reference: this.paymentRequest.data.reference,
        payment_method: "NEQUI",
        nequi_method: message,
        client: this.paymentRequest.data.client,
        email: this.paymentRequest.data.email,
        description: this.paymentRequest.data.description,
        tokenNequi: nequiToken,
        subclient: this.paymentRequest.data.subclient ?? null
      });

      const body = {
        dev_reference: this.paymentRequest.data.reference ? this.paymentRequest.data.reference : null
      }

      await this.changeStatusService.changeStatus(this.auth, this.paymentRequest, message, body);

      if(nequiToken){
        const verifyPayment = await this.verifyPayment('PN',false);
        const newPushCode = await this.nequiService.unregisteredPayment(request, token).toPromise();
        this.disableUntilResponse = false;
        this.messageTime = true;
        this.loadingScreenService.stopLoading();

        if(verifyPayment) {
          this.countDown();
        }

        this.qrCodeValue = newPushCode.response.unregisteredPaymentRS.transactionId;
      }
      this.nequiService.nequiPending(true);
      this.datalayerService.checkoutEvent('8', this.paymentRequest);
    } catch (error) {
      const tmpMessage = error.error.errorMessage ? error.error.errorMessage.split(":") : "";
      this.errorMessage = nequiCodeErrorMessage[tmpMessage[0]] ?? tmpMessage[1];
      this.messageTime = false;
      this.disableUntilResponse = false;
      this.loadingScreenService.stopLoading();
      this.pushError = true;
      this.showMessageError(this.errorMessage, 15000);
      this.showButtonPushNotification=true;
    }

  }

  //verifyPayment
  async verifyPayment(channel: string, unregistredFlag = true, pendingFlag = false): Promise<NequiResponse | null> {
    try {
      this.pushError = false;
      this.QrError = false;
      this.showPendingMessageQR = false;
      if(channel=="PN"){
        this.nequiMethod="nequi_push";
      }
      if(channel=="QR"){
        this.nequiMethod="nequi_qr";
      }
      if(channel=="EE"){
        this.nequiMethod="nequi_ee";
      }
      this.loadingScreenService.startLoading();
      const nequiToken = await this.getTokenNequi();
      const token = 'Bearer ' + this.auth.token;
      const request = this.encryptService.encryptCipherData({
        reference: this.paymentRequest.data.reference,
        amount: this.paymentRequest.data.amount,
        codeQR: this.qrCodeValue,
        email: this.paymentRequest.data.email,
        payment_method: "NEQUI",
        nequi_method:this.nequiMethod,
        tokenNequi: nequiToken,
        channel: channel,
        subclient: this.paymentRequest.data.subclient ?? null,
        isUnregistredPayment: !unregistredFlag,
        isPendingPayment: pendingFlag
      });
      const verifyPayment: any = await this.nequiService.verifyPayment(request, token).toPromise();

      if (verifyPayment.status == NequiStatus.APPROVED) {
        this.loadingScreenService.stopLoading();
        this.router.navigate(["/pagos/respuesta"], {
          state: {
            paymentRequest: this.paymentRequest,
            response: {
              transaction: {
                status: verifyPayment.status,
                nequi: {
                  code: verifyPayment.code,
                  amount: this.paymentRequest.data.amount,
                  transactionID: verifyPayment ? verifyPayment.transactionID : null,
                  paymentDate: verifyPayment ? verifyPayment.paymentDate : null,
                }
              },
            },
          },
        });
      }

      if (verifyPayment.status == NequiStatus.PENDING) {

        this.pushError = channel == "PN" ? true: false;
        this.QrError = channel ==   "QR" ? true: false;
        this.isPending = true;
        this.nequiService.nequiPending(true);
          this.loadingScreenService.stopLoading();
          this.showMessage = true;
        this.showMessageError(this.pendingMessageQR, 15000);
      }

      if(verifyPayment == false){
        this.nequiService.nequiPending(false);
      }

      if (verifyPayment.status == NequiStatus.TIMEOUT) {

        this.QrError = channel ==   "QR" ? true: false;
        this.pushError = channel == "PN" ? true: false;
        if(unregistredFlag) {
          this.loadingScreenService.stopLoading();
          this.showMessageError(this.timeoutMessageQR, 15000);
          this.generateNewCodeQr("NEQUI_QR");
        }
        this.nequiService.nequiPending(false);
        this.countDown();
      }

      if (verifyPayment.status == NequiStatus.UNEXIST){
        this.nequiService.nequiPending(false);
        this.loadingScreenService.stopLoading();
      }

      if (verifyPayment.status == NequiStatus.CANCELED){
        this.nequiService.nequiPending(false);
        this.loadingScreenService.stopLoading();
      }

      if (!this.qrCodeValue) {

        if(unregistredFlag) {

          this.loadingScreenService.stopLoading();
          this.pushError = channel == "PN" ? true: false;
          this.QrError = channel ==   "QR" ? true: false;

          if(verifyPayment.status == NequiStatus.PENDING) this.showMessageError(this.noCodeMessage, 15000);
          else{
            this.nequiService.nequiPending(false);
          }
        }
      }

      return verifyPayment;
    } catch (error) {

      if(unregistredFlag){
        this.loadingScreenService.stopLoading();

        const tmpMessage = error.error.errorMessage.split(":");
        this.messageTime = !(tmpMessage[0] == '10-455'
                          || tmpMessage[0] == '10-454'
                          || tmpMessage[0] == '11-18L'
                          );
        this.errorMessage = nequiCodeErrorMessage[tmpMessage[0]] ?? tmpMessage[1];
        this.pushError = channel == "PN" ? true: false;
        this.QrError = channel ==   "QR" ? true: false;
         this.showMessageError(this.errorMessage, 15000);
      }
      return null;
    }
  }

  consultQrTransactionPaymentez(): void {
    this.generateNewCodeQr("NEQUI_QR");

  }

  isActiveEE() {
    this.tokenNequi = this.paymentRequest.data.tokenNequi ?? '';
    if (this.tokenNequi != '') {
      this.activeEmbeddedExperience = true;
      this.activePushNotification = false;
      this.activeQR = false;
    } else {
      this.activeQR = this.paymentRequest.configParamsNequi.nequiQrActive;
      this.activePushNotification = this.paymentRequest.configParamsNequi.nequiPushNotificationActive;
    }
  }


  async showMessageError(message: string, timeMilliseconds = 20000) {
    this.errorMessage = message;
    setTimeout(() => {
      if (this.errorMessage != null) {
        this.errorMessage = null
      }
    }, timeMilliseconds);

    this.showButtonPushNotification=true;

    this.auditAttemptedStatusError(this.errorMessage);
  }

  public auditAttemptedStatusError(message: string) {
    const auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
    let audit = {
      reference: this.paymentRequest.data.reference,
      customer_email: this.paymentRequest.data.email,
      client: this.paymentRequest.name,
      payment_reference: this.paymentRequest.data.payment_reference,
      response_token: null,
      error: null,
      dev_message: message
    };
    this.auditError = {
      "client": this.paymentRequest.name,
      "reference": this.paymentRequest.data.reference,
      "payment_reference": this.paymentRequest.data.payment_reference
    }
    this.changeStatusService.auditErrorStatusAttempted(audit, this.auditError, auth.token);
  }


  inputIt(data: number) {
    this.disableUntilResponse = data.toString().length != 10;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }


  getRemainTime(deadline){
    let now = new Date(),
      remainTime = (new Date(deadline).getTime() - now.getTime() + +1000)/1000,
      remainSeconds = ('0' + Math.floor(remainTime % 60)).slice(-2),
      remainMinutes = ('0' + Math.floor(remainTime / 60 % 60)).slice(-2);

      return {
        remainTime,
        remainSeconds,
        remainMinutes
      }
  };

  countDown() {
    clearInterval(this.timerUpdate);

    this.currentDate = new Date();
    this.targetTime = this.currentDate;
    this.targetTime.setSeconds(this.currentDate.getSeconds() + 70);

    this.timerUpdate = setInterval(() => {
      let t = this.getRemainTime(this.targetTime);

      if (parseInt(t.remainMinutes, 10) > -1) {
        this.timePN = t.remainMinutes + ":" + t.remainSeconds;
      }

      if (t.remainTime <= 1 ) {
        clearInterval(this.timerUpdate);
        this.verifyPayment('PN');
        this.showButtonPushNotification = true;
      }
    }, 1000)
  }

  showPaymentMethod() {
    if (this.paymentRequest.data.requires_split_payment == true && this.paymentRequest.data.dataSplitTransactions.length) {
      return false;
    }
    return true;
  }
}
