import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PaymentConfigResponseModel} from '../models/payment-config-response.model';
import {environment} from '../../../environments/environment';
import { ConfigurationModel } from '../models/configuration.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(
    private http: HttpClient
  ) {}

  getConfigVars(name_client : string, token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');
    return this.http.get<PaymentConfigResponseModel>(environment.apiUrl + '/api/payment-attempts?name=' + name_client, {headers});
  }

  public setConfigVars(configuration: ConfigurationModel, token: string): Observable<any> {

    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');
    return this.http.post<string>(environment.apiUrl + '/api/save-payment-attempts', configuration, { headers });

  }

  getConfiguration(obj : any, token: string): Observable<PaymentConfigResponseModel> {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');
    return this.http.post<PaymentConfigResponseModel>(environment.apiUrl + '/api/check-client',  obj, {headers});
  }

  getPlatformNames(token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');
    return this.http.get<PaymentConfigResponseModel>(environment.apiUrl + '/api/active-clients', {headers});
  }

  getGateWayActive(name:string,token: string): Observable<any>{
    const headers= new HttpHeaders()
    .set('Authorization',token)
    return this.http.get<PaymentConfigResponseModel>(environment.apiUrl + `/api/get-active-gateways?name=${name}`,{headers})
  }

  editConfgClient(client: any, token: string): Observable<any>{
    const headers = new HttpHeaders()
      .set('Authorization',token)
      .set('Content-Type', 'application/json');
    return this.http.post<PaymentConfigResponseModel>(environment.apiUrl + `/api/config-client`, client, {headers})
  }

  getPaymentMethods(): Observable<any> {
    return this.http.get<PaymentConfigResponseModel>(environment.apiUrl + '/load/payment-methods');
  }

  updateStatusPay(request: any, token:string){
    const headers = new HttpHeaders()
      .set('Authorization',token)
      .set('Content-Type', 'application/json');
    return this.http.post(environment.apiUrl + `/api/update-status-transaction`, request, {headers})
  }

  updateBankList(token, request) {
    const headers = new HttpHeaders()
      .set('Authorization', token)
      .set('Content-Type', 'application/json');
    return this.http.post(environment.apiUrl + `/api/config/update-banks`, request, { headers });
  }
}
