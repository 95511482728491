import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { TransactionData } from './transaction-data';

@Injectable({
  providedIn: 'root',
})
export class TransactionDataService {
  #transactionData = new BehaviorSubject<TransactionData>(null);

  get transactionData$(): BehaviorSubject<TransactionData> {
    return this.#transactionData;
  }

  get transactionData(): TransactionData {
    return this.#transactionData.getValue();
  }

  setTransactionData(value: TransactionData) {
    this.#transactionData.next(value);
  }
}
