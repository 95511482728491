import { Injectable, Inject, Injector } from '@angular/core';
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})
export class CookieGatewayService {

  static readonly COOKIE_LOGGED = 'isLogged';
  static readonly COOKIE_PAYMENT = 'payment';
  static readonly GA_COOKIE  = '_ga';
  static readonly GID_COOKIE = '_gid';
  static readonly USER_EMAIL = 'user_email';

  constructor(
    private cookieService: CookieService,

  ) { }

  public getCookie(keyCookie: string): string {

    let __cookie;
    __cookie = this.cookieService.get(keyCookie);
    return __cookie;

  }

  public setCookie(keyCookie: string, value: string, expires?: any): void {
    this.cookieService.set(keyCookie, value, expires, '/', null, null, null);
  }

  public removeCookie(keyCookie: string): void {
    this.cookieService.delete(keyCookie, '/');
  }

}
