// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "https://pre.api-gateway.solucionesbolivar.com",
  urlPaymentezConsult: "https://noccapi-stg.paymentez.com",
  urlPaymentezTransaction:"https://ccapi-stg.paymentez.com/v2/transaction",
  //urlResponsePSE:"https://dev-pagos.ciencuadras.com/pagos/respuesta",
  urlCdnPaymentez:"https://cdn.paymentez.com/js/ccapi/stg/paymentez.min.js",
  secretKey:"c1encu4dr@s",
  urlPaymentezCheckoutSDK: "https://cdn.paymentez.com/checkout/1.0.1/paymentez-checkout.min.js",
  paymentezEnviromentMode: "stg",
  urlApi: 'https://pre.api-gateway.solucionesbolivar.com/api',
  secretTimeKey: 'bdcbb26c55ead7448c09242cabca3b68',
  secretTimeIv: 'f4db037c67154644e51e9800ec0c66db',
  defaultIp:'181.63.11.102'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
