import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSliderChange } from "@angular/material/slider";
import { Router, RouterLink } from '@angular/router';
import { LoginModel } from 'src/app/core/models/login.model';
import { PaymentConfigModel } from 'src/app/core/models/payment-config.model';
import { PuntosColombiaResponse } from 'src/app/core/models/puntos-colombia-response.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { ChangeStatusService } from 'src/app/core/services/change-status.service';
import { EncryptService } from 'src/app/core/services/encrypt.service';
import { EpaycoService } from 'src/app/core/services/epayco.service';
import { LoadingScreenService } from 'src/app/core/services/loading-screen.service';
import { PuntosColombiaService } from 'src/app/core/services/puntos-colombia.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { PuntosColombiaDialogComponent } from 'src/app/commons/puntos-colombia-dialog/puntos-colombia-dialog.component'
import * as errorResponse from 'src/app/core/utilities/puntos-colombia-error-response';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { NequiService } from 'src/app/core/services/nequi.service';
import { DatalayerService } from 'src/app/core/services/datalayer.service';

@Component({
  selector: 'app-pay-puntos-colombia',
  templateUrl: './pay-puntos-colombia.component.html',
  styleUrls: ['./pay-puntos-colombia.component.scss']
})

export class PayPuntosColombiaComponent implements OnInit, OnDestroy {
  @Input() paymentRequest: PaymentConfigModel;
  @ViewChild("maxPointsInput") maxPointsInput: ElementRef;
  @ViewChild(MatExpansionPanel) puntosColombiaPanel: MatExpansionPanel;


  pointsUser: any;
  minimumPoints: number;
  sliderMax: number;
  sliderMaxValue: number;
  sliderValue: number;
  changeCard: boolean;
  auth;
  infoUserResponse: PuntosColombiaResponse
  responseOtp: PuntosColombiaResponse;

  public elementsPass: any;
  step: number = 0;
  resendButton = false;
  sendMethods: object = {};
  methodCodeOption: string = 'sms';
  codeOtp: string = '';
  payMixedPoints: boolean = false;
  showButtonNext: boolean = true;
  loading: boolean = false;
  invalidPayPoints = false;
  invalidPayMessage = '';
  messageRegister = false;
  incorrectResponseRedemption = '';
  incorrectResponseRedemptionMixed = '';
  incorrectResponseOtpCode = '';
  errorResponse = errorResponse.errorResponse;
  loadingOtp = false;
  otpAttemptedEmail: number = 0;
  otpAttemptedSms: number = 0;
  amountMixedPuntos: number = 0
  totalAmountPayCreditCard: number;
  totalAmount: number;
  activeIsPC: boolean = false;
  validateNitClient: boolean = false;
  paymenMethod: string = 'PUNTOS_COLOMBIA_REDENCION';
  auditError: any;
  disableNextView: boolean = false;

  dataInfoTX: any = {}

  documentType = '';
  documentNumber = '';
  authDocumentType = '';
  authDocumentNo = '';
  pendingNequiPayment: boolean = false;
  documents = [
    { code: "2", name: "Cédula de Ciudadania" },
    { code: "3", name: "Cédula de Extranjeria" },
    { code: "5", name: "Nit" },
  ]

  constructor(
    private puntosColombiaService: PuntosColombiaService,
    private sessionStorageService: SessionStorageService,
    private loadScreenService: LoadingScreenService,
    private encryptService: EncryptService,
    private router: Router,
    private authService: AuthService,
    private changeStatusService: ChangeStatusService,
    private epaycoService: EpaycoService,
    public dialog: MatDialog,
    private nequiService: NequiService,
    private datalayerService: DatalayerService
  ) {
    this.auth = this.sessionStorageService.getItem<LoginModel>(SessionStorageService.AUTH);
  }

  ngOnInit(): void {
    this.totalAmount = this.paymentRequest.data.amount;
    this.payMixedPoints = this.paymentRequest.isMixedPaymentPC ?? false;

    if (this.payMixedPoints) {
      this.activeIsPC = true;
      this.paymenMethod = "PUNTOS_COLOMBIA_PAGO_MIXTO"
    }
    if (this.paymentRequest.data.id_type == "NIT" && this.paymentRequest.data.type_user == "J") {
      this.validateNitClient = true;
    }

    this.elementsPass = document
      .getElementsByClassName("input-pc")

    this.nequiService.nequiPendingEmitter.subscribe(res => {
      this.pendingNequiPayment = res;
    })
  }

  ngOnDestroy(): void {
    this.sessionStorageService.removeItem(SessionStorageService.PUNTOS_TOKEN);
  }

  async nextView() {
    this.messageRegister = false;
    this.datalayerService.checkoutEvent('6', this.paymentRequest);
    if (this.step == 0 && this.validateInfo()) {
      await this.validateUserPoints();
      if (!this.invalidPayPoints && this.activeIsPC) {
        this.setTotalAmountPayCreditCard();
      }
    }

    if (this.step == 2) {
      this.showButtonNext = true;
      await this.getOtpCode();
      this.validateLengthOtp()
    }

    if (this.step == 3) {
      if (!this.activeIsPC) {
        this.redemptionPoints();
      } else {
        this.redemptionPointsWithMixedPayment()
      }
    } else if (!this.invalidPayPoints && this.incorrectResponseOtpCode == '') {
      this.step = this.step == 3 ? 0 : this.step + 1;
    }
  }

  slideIt(matSliderChange: MatSliderChange) {
    if (matSliderChange.value > this.sliderMaxValue) {
      this.maxPointsInput.nativeElement.value = this.sliderMaxValue;
      matSliderChange.source.value = this.sliderMaxValue;
      this.sliderValue = this.sliderMaxValue;
    }
    this.setTotalAmountPayCreditCard();
  }
  inputIt(data: number) {
    if (data >= this.sliderMaxValue) {
      this.maxPointsInput.nativeElement.value = this.sliderMaxValue;
      this.sliderValue = this.sliderMaxValue;
    }
    if (this.minimumPoints > data) {
      this.sliderValue = this.minimumPoints;
    }
    this.setTotalAmountPayCreditCard();

  }

  addPoints() {
    if (this.sliderValue < this.sliderMaxValue) {
      this.sliderValue++;
    }
    this.setTotalAmountPayCreditCard();
  }

  substractPoints() {
    if (this.sliderValue > this.minimumPoints) {
      this.sliderValue--;
    }
    this.setTotalAmountPayCreditCard();
  }

  numberOnly(event): boolean {
    this.messageRegister = false;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  private dataMock() {
    this.minimumPoints = 10;

    this.pointsUser = {
      allowAccrual: true,
      allowRedemption: false,
      email: "r*****a@g***l.com",
      mainBalance: {
        pointsValueInMoney: 23765.0,
        expiringPoints: 350,
        pointsAmount: 3395,
        pointsMoneyRatio: 7.0,
        expirationDate: "2022-08-31"
      },
      mobilePhoneNo: "300****810",
      name: "Maria",
      totpActive: false
    };

    const totalPointsClient = this.pointsUser.mainBalance.pointsAmount;
    this.sliderMax = this.pointsUser.mainBalance.pointsAmount;
    this.sliderValue =
      totalPointsClient >= this.sliderMax ? this.sliderMax : totalPointsClient;

    this.sliderMaxValue = this.sliderValue;

  }
  selectCardType(type: any) {

    if (type == "credit") {
      this.changeCard = false;
    }

    if (type == "debit") {
      this.changeCard = true;
    }
  }

  async getTokenPuntos() {
    let tokenPuntos;
    const token = "Bearer " + this.auth.token;

    try {
      tokenPuntos = this.sessionStorageService.getItem(SessionStorageService.PUNTOS_TOKEN);
      if (!tokenPuntos) {
        const response = await this.puntosColombiaService.getToken(this.paymentRequest.data.reference, this.paymentRequest.data.subclient, token).toPromise();
        if (response.success) {
          tokenPuntos = response.data.token;
          tokenPuntos = this.authService.decryptDataPlain(response.data.token);
          this.sessionStorageService.setItem(SessionStorageService.PUNTOS_TOKEN, tokenPuntos)
        } else {
          this.invalidPayMessage = response.data.errorMessage;
          this.invalidPayPoints = true;
        }
      }
      return tokenPuntos

    } catch (error) {
    }
  }

  async validateUserPoints() {
    this.loadScreenService.startLoading();
    const tokenPuntos = await this.getTokenPuntos();

    if (!tokenPuntos) {
      this.loadScreenService.stopLoading();
      return false;
    }
    const request = {
      reference: this.paymentRequest.data.reference,
      docNumber: this.documentNumber,
      docType: this.documentType,
      tokenPuntos: tokenPuntos,
      authDocumentNo: this.documentType == "5" ? this.authDocumentNo : null,
      authDocumentType: this.documentType == "5" ? this.authDocumentType : null,
      subclient: this.paymentRequest.data.subclient ?? null
    };

    const body = {
      dev_reference: this.paymentRequest.data.reference ? this.paymentRequest.data.reference : null
    }
    await this.changeStatusService.changeStatus(this.auth, this.paymentRequest, this.paymenMethod, body);

    this.infoUserResponse = await this.puntosColombiaService.getPointsUser(request, 'Bearer ' + this.auth.token).toPromise();

    this.infoUserResponse.data.infoUserPoints = this.authService.decryptDataPlain(this.infoUserResponse.data.infoUserPoints);
    this.loadScreenService.stopLoading();

    if (this.infoUserResponse.success) {
      if (!this.infoUserResponse.data.infoUserPoints.allowRedemption) {
        this.invalidPayMessage = 'Cliente no autorizado para realizar esta transacción';
        this.invalidPayPoints = true;
        this.auditAttemptedStatusError(this.invalidPayMessage);

      }
      if (this.activeIsPC) {
        this.configParamsSliderPayMixed(this.infoUserResponse);

      }
      if (!this.activeIsPC) {
        this.configParamsSlider(this.infoUserResponse);
      }

      if (this.infoUserResponse.data.infoUserPoints.allowRedemption && !this.activeIsPC) {
        this.validSliderPointsWitOutRedemption(this.infoUserResponse);
      }

      if (this.infoUserResponse.data.infoUserPoints.allowRedemption && this.activeIsPC) {
        //TODO PAGO MIXTO
        this.validSliderPointsMixedPayment(this.infoUserResponse)
      }
    }

    if (!this.infoUserResponse.success) {
      if (this.infoUserResponse.data.errorMessage) {
        this.invalidPayMessage = this.errorResponse[this.infoUserResponse.data.errorMessage] ?? this.infoUserResponse.data.errorMessage;
        this.invalidPayPoints = true;
        this.auditAttemptedStatusError(this.invalidPayMessage);
        if (this.infoUserResponse.data.errorMessage == "POS_CUSTOMER_NOT_FOUND") {
          this.messageRegister = true;
        }
      }
    }
  }

  public configParamsSlider(infoUserResponse: PuntosColombiaResponse) {
    this.sliderMax = Math.ceil(this.totalAmount / infoUserResponse.data.infoUserPoints.mainBalance.pointsMoneyRatio);
    this.minimumPoints = 200;

    this.sliderValue = this.sliderMax;
  }

  public configParamsSliderPayMixed(infoUserResponse: PuntosColombiaResponse) {
    this.sliderMax = Math.ceil(this.totalAmount / infoUserResponse.data.infoUserPoints.mainBalance.pointsMoneyRatio);
    if (infoUserResponse.data.infoUserPoints.mainBalance.pointsValueInMoney > this.totalAmount) {
      this.sliderMaxValue = this.sliderMax;
    } else {
      this.sliderMaxValue = infoUserResponse.data.infoUserPoints.mainBalance.pointsAmount;
    }
    this.minimumPoints = 200;
    this.sliderValue = this.sliderMaxValue;
  }


  public validSliderPointsWitOutRedemption(infoUserResponse: PuntosColombiaResponse) {
    this.invalidPayPoints = true;
    this.invalidPayMessage = 'No cuentas con el saldo de Puntos suficiente';

    if (this.sliderValue < 200) {
      this.invalidPayMessage = `El valor mínimo de puntos a redimir es ${this.minimumPoints}`;
      this.auditAttemptedStatusError(this.invalidPayMessage);

    } else if (infoUserResponse.data.infoUserPoints.mainBalance.pointsValueInMoney >= this.totalAmount) {
      this.invalidPayPoints = false;
      this.sendMethods = { 'email': infoUserResponse.data.infoUserPoints.email, 'sms': infoUserResponse.data.infoUserPoints.mobilePhoneNo };
    }
    if (this.invalidPayPoints) {
      this.auditAttemptedStatusError(this.invalidPayMessage);
    }
  }

  public validSliderPointsMixedPayment(infoUserResponse: PuntosColombiaResponse) {
    this.invalidPayPoints = true;
    this.invalidPayMessage = `El valor mínimo de puntos a redimir es ${this.minimumPoints}`;

    if (infoUserResponse.data.infoUserPoints.mainBalance.pointsAmount < this.minimumPoints) {
      this.invalidPayMessage = 'No cuentas con el saldo de Puntos suficiente';
    } else if (this.sliderValue >= this.minimumPoints) {
      this.invalidPayPoints = false;
      this.sendMethods = { 'email': infoUserResponse.data.infoUserPoints.email, 'sms': infoUserResponse.data.infoUserPoints.mobilePhoneNo };
    }
  }

  public async getOtpCode() {
    this.resendButton = false;
    this.loadScreenService.startLoading();
    const tokenPuntos = await this.getTokenPuntos();

    const request = {
      reference: this.paymentRequest.data.reference,
      docNumber: this.documentNumber,
      docType: this.documentType,
      shippingMethod: this.methodCodeOption,
      tokenPuntos: tokenPuntos,
      authDocumentNo: this.documentType == "5" ? this.authDocumentNo : null,
      authDocumentType: this.documentType == "5" ? this.authDocumentType : null,
      subclient: this.paymentRequest.data.subclient ?? null
    }

    this.responseOtp = await this.puntosColombiaService.getOtpCode(request, 'Bearer ' + this.auth.token).toPromise();
    this.responseOtp.data.responseOtp = this.authService.decryptDataPlain(this.responseOtp.data.responseOtp);
    this.loadScreenService.stopLoading();
    if (!this.responseOtp.success) {
      this.incorrectResponseOtpCode = this.errorResponse[this.responseOtp.data.errorMessage] ?? this.responseOtp.data.errorMessage;
      this.auditAttemptedStatusError(this.incorrectResponseOtpCode);

    }
    this.codeOtp = '';
    this.incorrectResponseRedemption = ''
  }

  public async redemptionPoints() {

    if (this.responseOtp) {
      this.loadScreenService.startLoading();
      const tokenPuntos = await this.getTokenPuntos();
      const request = {
        reference: this.paymentRequest.data.reference,
        docNumber: this.documentNumber,
        docType: this.documentType,
        otpCode: this.codeOtp,
        mainPointsRedeemed: this.totalAmount,
        subclient: this.paymentRequest.data.subclient ?? null,
        tokenPuntos: tokenPuntos,
        authDocumentNo: this.documentType == "5" ? this.authDocumentNo : null,
        authDocumentType: this.documentType == "5" ? this.authDocumentType : null
      }

      const responseRedemption = await this.puntosColombiaService.redemptionPoints(request, 'Bearer ' + this.auth.token).toPromise();
      responseRedemption.data.responseRedemption = this.authService.decryptDataPlain(responseRedemption.data.responseRedemption);
      this.loadScreenService.stopLoading();
      if (!responseRedemption.success) {
        this.incorrectResponseRedemption = this.errorResponse[responseRedemption.data.errorMessage] ?? responseRedemption.data.errorMessage;
        this.auditAttemptedStatusError(this.incorrectResponseRedemption);
        this.validateAttemptedOtp(responseRedemption);
      }

      if (responseRedemption.success) {
        this.router.navigate(['/pagos/respuesta'], {
          state: {
            paymentRequest: this.paymentRequest,
            response: {
              transaction: {
                status: responseRedemption.data.status,
                puntosColombia: {
                  mainPointsBurned: responseRedemption.data.responseRedemption.mainPointsBurned,
                  PointsBurned: responseRedemption.data.responseRedemption.mainBalance,
                  transactionId: responseRedemption.data.responseRedemption.transactionIdentifier.transactionId,
                  transactionDate: responseRedemption.data.responseRedemption.transactionIdentifier.transactionDate
                }
              }
            }
          }
        });
      }
    }
  }

  public async redemptionPointsWithMixedPayment() {

    this.loadScreenService.startLoading();
    const tokenPuntos = await this.getTokenPuntos();

    this.dataInfoTX.client = this.paymentRequest.data.client
    this.dataInfoTX.amount = this.totalAmountPayCreditCard
    this.dataInfoTX.doc_type = this.paymentRequest.data.id_type
    this.dataInfoTX.doc_number = this.paymentRequest.data.id
    this.dataInfoTX.name = this.paymentRequest.data.name
    this.dataInfoTX.last_name = this.paymentRequest.data.last_name ? this.paymentRequest.data.last_name : "‎ ",
      this.dataInfoTX.email = this.paymentRequest.data.email
    this.dataInfoTX.phone = this.paymentRequest.data.phone
    this.dataInfoTX.type_user = this.paymentRequest.data.type_user
    this.dataInfoTX.description = this.paymentRequest.data.description
    this.configureTax();
    this.dataInfoTX.reference = this.paymentRequest.data.reference
    this.dataInfoTX.payment_reference = this.paymentRequest.data.payment_reference;
    this.dataInfoTX.subclient = this.paymentRequest.data.subclient ?? null;
    this.dataInfoTX.dataRedemmed = {
      reference: this.paymentRequest.data.reference,
      docNumber: this.documentNumber,
      docType: this.documentType,
      otpCode: this.codeOtp,
      mainPointsRedeemed: this.amountMixedPuntos,
      tokenPuntos: tokenPuntos
    }
    let ResponseRedemptionMixed = await this.puntosColombiaService.redemptionPayMixedPonts(this.dataInfoTX, 'Bearer ' + this.auth.token).toPromise();
    ResponseRedemptionMixed.data.responseRedemption = this.authService.decryptDataPlain(ResponseRedemptionMixed.data.responseRedemption);
    ResponseRedemptionMixed.data.responseCard = this.authService.decryptDataPlain(ResponseRedemptionMixed.data.responseCard);

    this.loadScreenService.stopLoading();

    if (!ResponseRedemptionMixed.success) {
      this.incorrectResponseRedemptionMixed = this.errorResponse[ResponseRedemptionMixed.data.errorMessage] ?? ResponseRedemptionMixed.data.errorMessage;
      this.auditAttemptedStatusError(this.incorrectResponseRedemptionMixed);
    }

    if (ResponseRedemptionMixed.success) {
      this.router.navigate(['/pagos/respuesta'], {
        state: {
          paymentRequest: this.paymentRequest,
          response: {
            transaction: {
              status: ResponseRedemptionMixed.data.status,
              puntosColombia: {
                mainPointsBurned: ResponseRedemptionMixed.data.responseRedemption.mainPointsBurned,
                PointsBurned: ResponseRedemptionMixed.data.responseRedemption.mainBalance,
                transactionId: ResponseRedemptionMixed.data.responseRedemption.transactionIdentifier.transactionId,
                transactionDate: ResponseRedemptionMixed.data.responseRedemption.transactionIdentifier.transactionDate,
                amountCreditCard: ResponseRedemptionMixed.data.responseCard.data.amount,
                franchise: this.epaycoService.franchiseSet(ResponseRedemptionMixed.data.responseCard.data.franquicia),
                installments: this.dataInfoTX.dues ? this.dataInfoTX.dues : '1',
                payMixed: true
              }
            }
          }
        }
      });
    }
  }

  public validateAttemptedOtp(responseQr) {

    if (!responseQr.success && this.methodCodeOption == 'email') {
      this.otpAttemptedEmail++;
      console.log('otpAttemptedEmail', this.otpAttemptedEmail);
      if (this.otpAttemptedEmail >= 3) {
        this.methodCodeOption = 'sms'
        this.step = 2;
      }
    }

    if (!responseQr.success && this.methodCodeOption == 'sms') {
      this.otpAttemptedSms++;
      console.log('otpAttemptedSms', this.otpAttemptedSms);
      this.attemptsCompleted()
      if (this.otpAttemptedSms >= 3) {
        this.methodCodeOption = 'email'
        this.step = 2;
      }
    }

    this.resendButton = responseQr.success ? false : true;
  }

  attemptsCompleted() {
    if (this.otpAttemptedEmail >= 3 && this.otpAttemptedSms >= 3) {
      this.openDialog()
      this.disableNextView = true;
      return false;
    }
    return true;
  }

  validateInfo() {
    let validate = true;
    if (this.documentNumber == '' || this.documentType == '') {
      this.invalidPayPoints = true;
      this.invalidPayMessage = 'Digite todos los campos'
      validate = false;
    }

    return validate;
  }

  validateLengthOtp() {
    if (this.codeOtp.length < 6) {
      this.disableNextView = true
    } else {
      this.disableNextView = false
    }
  }

  private setTotalAmountPayCreditCard() {
    this.amountMixedPuntos = this.sliderValue * this.infoUserResponse.data.infoUserPoints.mainBalance.pointsMoneyRatio;

    this.totalAmountPayCreditCard =
      this.totalAmount - this.amountMixedPuntos;

    if (this.totalAmount - this.amountMixedPuntos < 0) {
      this.totalAmountPayCreditCard = 0
    }

    if (this.totalAmount <= this.amountMixedPuntos) {
      this.showButtonNext = true;
      this.activeIsPC = false
    } else {
      this.showButtonNext = false;
      this.activeIsPC = true
    }
  }

  sendDataCard(data) {

    this.dataInfoTX.dues = data.dues;

    if (data.card) {
      this.dataInfoTX.card_name = data.card.name;
      this.dataInfoTX.card_number = data.card.number;
      this.dataInfoTX.card_cvc = data.card.cvc;
      this.dataInfoTX.card_exp_month = data.card.month;
      this.dataInfoTX.card_exp_year = data.card.year;
      this.dataInfoTX.mask = this.epaycoService.maskCard(data.card.number);
    }

    this.dataInfoTX.exist_token_card = data.exist_token_card;
    this.dataInfoTX.save_card = data.save_card,
      this.dataInfoTX.token_card = data.token_card
    this.dataInfoTX.ip = data.ip;
    this.step = 2
    this.showButtonNext = true;
  }

  configureTax() {
    let _tax = 0;
    let _tax_base = 0;
    let vat = this.paymentRequest.data.vat;
    if (vat > 0) {
      let amount = this.totalAmountPayCreditCard
      _tax = amount * (vat / 100);
      _tax_base = amount - _tax;
    }
    this.dataInfoTX.tax = String(_tax);
    this.dataInfoTX.tax_base = String(_tax_base);
  }

  public auditAttemptedStatusError(message: string) {

    let audit = {
      reference: this.paymentRequest.data.reference,
      customer_email: this.paymentRequest.data.email,
      client: this.paymentRequest.name,
      payment_reference: this.paymentRequest.data.payment_reference,
      response_token: null,
      error: null,
      dev_message: message
    };
    this.auditError = {
      "client": this.paymentRequest.name,
      "reference": this.paymentRequest.data.reference,
      "payment_reference": this.paymentRequest.data.payment_reference
    }
    this.changeStatusService.auditErrorStatusAttempted(audit, this.auditError, this.auth.token);
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(PuntosColombiaDialogComponent, {
      width: '400px',
      height: '550px',
      data: { method: this.methodCodeOption },
    });

    dialogRef.afterClosed().subscribe(result => {

      if (this.disableNextView) {
        this.showButtonNext = false;
        this.puntosColombiaPanel.close()
        this.puntosColombiaPanel.disabled = true;
      }
    });
  }

  disabledPaymentMethod() {
    if (this.pendingNequiPayment) {
      return true;
    }
    if (this.paymentRequest.data.requires_split_payment == true && this.paymentRequest.data.dataSplitTransactions.length) {
      if(this.paymentRequest.data.subclient){
        return false;
      }
      return true;
    }
    return false;
  }

}
