import { Component, HostListener, inject, Input, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';

import {
  ConfigClient,
  StatusTransaction,
} from '../../../core/interfaces/payment-gateway-response.interface';
import { TransactionDataService } from '@payment-app/core/services/transaction-data';

@Component({
  selector: 'app-purchase-summary-transaction-info',
  templateUrl: './purchase-summary-transaction-info.component.html',
  styleUrls: ['./purchase-summary-transaction-info.component.scss'],
})
export class PurchaseSummaryTransactionInfoComponent implements OnInit {
  @Input() transaction: StatusTransaction;
  @Input() configurationClient: ConfigClient;

  counter: number;
  countDown: Subscription;
  tick = 1000;
  resultPayment: boolean;
  statusColor: string;
  StatusClass = {
    APROBADO: 'aprobado',
    RECHAZADO: 'fallida',
    PENDIENTE: 'pendiente',
    FALLIDO: 'fallida',
  };

  ngOnInit(): void {
    this.counter = this.configurationClient.countdown;
    this.validateRedirect();
    this.statusColor = this.StatusClass[this.transaction.status];
  }

  validateRedirect() {
    if (this.transaction.status === 'APROBADO') {
      this.resultPayment = true;

      this.countDown = timer(0, this.tick).subscribe(() => {
        --this.counter;
        if (this.counter <= 0) {
          this.countDown.unsubscribe();
          this.redirect();
        }
      });
    } else {
      this.resultPayment = false;
    }
  }

  redirect() {
    const request = {
      dev_reference: this.transaction.reference,
      client: this.transaction.client,
      subclient: this.transaction.subclient,
      leave_type: 'Salida por Redirección',
      payment_status: 'APROBADO',
      leave_page: true,
    };

    window.location.href = this.configurationClient.urlReturn;
  }

  retryPayment() {
    window.location.href = this.transaction.paylink;
  }

  @HostListener('window:click', ['$event'])
  clickOnButton($event) {
    if (this.counter && this.transaction.status === 'APROBADO') {
      if (
        $event.target.matches('.details-table-buttons *') ||
        $event.target.matches('.print-tag, .print-tag *')
      ) {
        this.countDown.unsubscribe();
        this.resultPayment = false;
      }
    }
  }
}
