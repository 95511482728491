import { FormPayment } from './form-payment.enum';
import { PaymentMethod } from './payment-method.interface';

const paymentMethods: Readonly<{ [key in FormPayment]: PaymentMethod }> = {
  [FormPayment.CREDIT_CARD]: {
    title: 'Tarjeta de crédito',
    pathIcons: ['assets/images/visa.png', 'assets/images/mastercard.png'],
    helpText: 'card_help',
    value: 1,
    haveHelp: true,
    name: FormPayment.CREDIT_CARD,
  },
  [FormPayment.DEBIT_CARD]: {
    title: 'Tarjeta Débito',
    pathIcons: ['assets/images/visa.png', 'assets/images/mastercard.png'],
    helpText: 'card_debit_help',
    value: 2,
    haveHelp: true,
    name: FormPayment.DEBIT_CARD,
  },
  [FormPayment.DEBIT_CREDIT_CARD]: {
    title: 'Tarjeta Débito o Crédito',
    pathIcons: ['assets/images/visa.png', 'assets/images/mastercard.png'],
    helpText: 'card_debit_help',
    value: 2,
    haveHelp: true,
    name: FormPayment.DEBIT_CREDIT_CARD,
  },
  [FormPayment.BANK_TRANSFER]: {
    title: 'Débito PSE',
    pathIcons: ['assets/images/pse.png'],
    helpText: 'pse_help',
    value: 3,
    haveHelp: false,
    name: FormPayment.BANK_TRANSFER,
  },
  [FormPayment.TOKENIZED_CARDS]: {
    title: 'Tarjetas Guardadas',
    pathIcons: ['assets/images/visa.png', 'assets/images/mastercard.png'],
    helpText: 'tokenized_cards',
    value: 4,
    haveHelp: false,
    name: FormPayment.TOKENIZED_CARDS,
  },
};

export class PaymentMethods {
  static get(key: FormPayment): PaymentMethod {
    return paymentMethods[key];
  }
}
