import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment as env } from '@environment/environment';
import { CdnLoaderService, NUVEI_PG } from '@core/services/cdn-loader';
import { TransactionDataService } from '@payment-app/core/services/transaction-data';

declare const PaymentGateway: any;

@Injectable({
  providedIn: 'root',
})
export class TokenizationFormService {
  readonly #cdnLoaderService = inject(CdnLoaderService);
  readonly #transactionDataService = inject(TransactionDataService);
  #isLoaded = new BehaviorSubject<boolean>(false);
  credentials = this.#transactionDataService.transactionData.portal.configuration.credentials.nuvei;

  constructor() {
    this.#cdnLoaderService.load(NUVEI_PG).then((response) => {
      this.#isLoaded.next(response[0].loaded);
    });
  }

  getPaymentGateway() {
    return new PaymentGateway(
      env.paymentezEnviromentMode,
      this.credentials.code,
      this.credentials.secret
    );
  }

  get isLoaded(): BehaviorSubject<boolean> {
    return this.#isLoaded;
  }
}
