import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentDataService {
  #callback = new BehaviorSubject(null);

  callToAction(callback: () => void) {
    this.#callback.next(callback);
  }

  get callback(): BehaviorSubject<() => void> {
    return this.#callback;
  }
}
