import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TokenizationPaymentGatewayService {
  readonly #http = inject(HttpClient);

  debitWithToken(body: {
    userId: string;
    email: string;
    amount: number;
    description: string;
    reference: string;
    vat: number;
    tokenCard: string;
    installments: number;
  }) {
    return this.#http.post<any>('payments/nuvei/debit-with-token', body);
  }

  saveToken(
    userId: string,
    appCode: string,
    body: {
      bin: string;
      status: string;
      token: string;
      message: string;
      expiry_year: string;
      expiry_month: string;
      transaction_reference: string;
      type: string;
      number: string;
      isCreditCard: boolean;
    },
  ) {
    this.#http.post<any>(`payments/nuvei/tokens/${userId}/${appCode}`, body).subscribe((response) => {
      console.log("service response", response);
    });
  }
}
