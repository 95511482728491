import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';

export type TypeAlert = 'success' | 'error' | 'warning' | 'info';

@Injectable({
  providedIn: 'root',
})
export class WarningMessageService {
  private readonly time = 3500;
  #alert = new BehaviorSubject<{
    message: string;
    description: string;
    type: TypeAlert;
  }>(null);

  alert(message: string, description: string, type: TypeAlert = 'success') {
    if (description !== null) {
      this.dismiss();
    }

    this.#alert.next({ message, description, type });
  }

  get alert$(): BehaviorSubject<{ message: string; description: string }> {
    return this.#alert;
  }

  private dismiss() {
    timer(this.time).subscribe(() => {
      this.#alert.next(null);
    });
  }
}
