import {Component, Input, NgZone, OnInit, ViewChild, AfterViewInit, Output, EventEmitter, inject} from '@angular/core';
import { PaymentConfigModel } from '../../core/models/payment-config.model';
import { PaymentezPaymentMethodModel } from '../../core/models/paymentez/paymentez-payment-method.model';
import { MatDialog } from '@angular/material/dialog';
import { PaymentezDialogComponent } from './paymentez-dialog/paymentez-dialog.component';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { PaymentezCheckoutService } from 'src/app/core/services/paymentez-checkout.service';
import { PaymentezService } from 'src/app/core/services/paymentez.service';
import { SessionStorageService } from '../../core/services/session-storage.service';
import { LoginModel } from '../../core/models/login.model';
import { SnackMessageComponent } from '../../commons/snack-message/snack-message.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ValidateBonusService } from "../../core/services/validate-bonus.service";
import { NequiStatus } from 'src/app/core/services/nequi.service';



// declare var PaymentezCheckout: any;
const url = 'https://cdn.paymentez.com/checkout/1.0.1/paymentez-checkout.min.js';

import { PaymentMethodService } from 'src/app/core/services/payment-method.service';
import { DefaultDialogComponent } from 'src/app/commons/default-dialog/default-dialog.component';
import { CardsService } from 'src/app/core/services/cards.service';
import { EpaycoService } from 'src/app/core/services/epayco.service';
import { AuthModel } from 'src/app/core/models/auth.model';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { LoadingScreenService } from 'src/app/core/services/loading-screen.service';
import { EncryptService } from 'src/app/core/services/encrypt.service';
import { PaymentTransactionService } from 'src/app/core/services/payment-transaction.service';
import { DatalayerService } from 'src/app/core/services/datalayer.service';
import Swal from 'sweetalert2';
import { ChangeStatusService } from 'src/app/core/services/change-status.service';
import { PaymentTransactionModel } from 'src/app/core/models/paymentez/payment-transaction.model';
import { DavipuntosCredentials } from 'src/app/payment/payment-davipuntos/payment-davipuntos.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { NequiService } from 'src/app/core/services/nequi.service';
import {Subject} from "rxjs";
import { RetentionsService } from '@payment-app/components/info-amount-to-pay/components/retentions/retentions.service';


declare var PaymentezCheckout: any;
declare var jQuery: any;
declare var $: any;
declare var window: any;
var nameCategory: any;

@Component({
  selector: 'gateway-paymentez',
  templateUrl: './paymentez.component.html',
  styleUrls: ['./paymentez.component.scss']
})
export class PaymentezComponent implements OnInit, AfterViewInit {
  @ViewChild('payment_methods') accordion: MatAccordion;
  @ViewChild('panelCheckEpayco') pnlCheckoutEpayco: MatExpansionPanel;
  @Input() paymentRequest: PaymentConfigModel;
  isOpenTC: Subject<boolean> = new Subject<boolean>();
  validatorRetentions: boolean = false;
  retentionApply: string;
  pendingNequiPayment: boolean = false;
  paymentMethods = {
    '1': 'TARJETA DE CREDITO',
    '2': 'PSE',
    '3': 'DAVIPLATA'
  };
  loading = false;
  flag = false;
  method: string;
  loadAPI: Promise<any>;
  daviplata = false;
  isNequiPending = false;
  gateway: PaymentezPaymentMethodModel[];
  auth;
  isDialogOpened = false;
  enabledPaymentMethods: {
    pse: boolean,
    card: boolean,
    mixed_purchase: boolean
  };
  isDebit = true;
  disableButton = false;
  loadingCards = true;
  cards: any = null;
  selectedCard = null;
  savedCardExpanded = false;
  cardBin: number;
  cardBinDebit: number;
  panelDaviPuntosDisabled = true;
  mixedPurchaseValidate = false;
  payWithPointsColombia = false;
  /** variable to show davipuntos payment method */
  hasDavipuntosTC = false;
  davipuntosCredentials: DavipuntosCredentials;
  #service = inject(RetentionsService);
  // onePayMixetPayment = false;
  //isOpenTC = false;

  constructor(
    public dialog: MatDialog,
    private ngZone: NgZone,
    protected _router: Router,
    private paymentezCheckoutService: PaymentezCheckoutService,
    private paymentMethodService: PaymentMethodService,
    private paymentService: PaymentezService,
    private changeStatusService: ChangeStatusService,
    private sessionStorageService: SessionStorageService,
    private _snackBar: MatSnackBar,
    private cardsService: CardsService,
    private sessionStorage: SessionStorageService,
    private epaycoService: EpaycoService,
    private loadingScreenService: LoadingScreenService,
    private encryptService: EncryptService,
    private paymentTransactionService: PaymentTransactionService,
    private validateBonusService: ValidateBonusService,
    private datalayerService: DatalayerService,
    private authService: AuthService,
    private nequiService: NequiService,
  ) {
    this.auth = this.sessionStorageService.getItem<LoginModel>(SessionStorageService.AUTH);
    this.cards = this.cardsService.getCards();
    this.cardsService.cardsObservable.subscribe(
      value => {
        this.loadingCards = false;
        this.cards = value;
      }
    );
  }

  async openDialog(debit = false) {
    const datos = this.sessionStorageService.getItem(SessionStorageService.ORDEN);
    const auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
    if (this.paymentRequest.productsAnalyticsObject) {
      this.sessionStorageService.setItem(SessionStorageService.PRODUCTSANALYTICS, this.paymentRequest.productsAnalyticsObject);
    }

    if (debit) {
      this.paymentRequest.data.amount = this.validatorRetentions ? this.paymentRequest.retentions.total_amount : this.paymentRequest.data.amount;
    }
    const body = {
      dev_reference: this.paymentRequest.data.reference ? this.paymentRequest.data.reference : null
    }
    try {
      await this.changeStatusService.changeStatus(auth, this.paymentRequest, 'CARD', body);
    }
    catch (error) {
      console.log(error);
    }
    // if(debit){
    //   this.paymentRequest.retentions = null;
    // }
    const method = debit ? '0' : this.method;
    this.loading = true;

    //datalayer boton de pago
    let amountDatalayer = this.paymentRequest.data.amount
    let textButton = `PAGAR ${amountDatalayer}`;
    if (this.paymentRequest.name == 'ciencuadras') {
      this.datalayerService.AddInfoDataLayerPaymentButton(textButton);
    }
    if (this.paymentRequest.name == 'doctoraki') {
      this.datalayerService.addInfoDataLayerPurchaseResume(textButton, this.paymentRequest.name, debit ? 'TD' : 'TC')
    }

    const bonusResult = await this.validarBono(this.paymentRequest);
    if (bonusResult || !(this.paymentRequest.manual_bonus && this.paymentRequest.manual_bonus_voucher)) {
      this.datalayerService.checkoutEvent(this.method, this.paymentRequest);
      if (this.method !== undefined && !this.disableButton) {

        this.disableButton = true;

        const datos = this.sessionStorageService.getItem(SessionStorageService.ORDEN);
        const token = 'Bearer ' + this.auth.token;

        this.paymentService.obtainStatusPayment(token, datos).subscribe(
          result => {
            if (result.status) {
              if (this.paymentRequest.gateway_active === 'Paymentez' && this.method === '1') {
                this.paymentezCheckoutService.inicializarPago(this.paymentRequest, debit);
                setTimeout(() => {
                  this.disableButton = false;
                  this.loading = false;
                }, 1500);
              } else {
                setTimeout(() => {
                  this.disableButton = false;
                }, 700);
                const dialogRef = this.dialog.open(PaymentezDialogComponent, {
                  // height: '630px',
                  width: '465px',
                  minHeight: '500px',
                  maxWidth: '100vw',
                  maxHeight: '100vh',
                  data: {
                    payment: this.paymentRequest,
                    methodPayment: this.method
                  }
                });
              }
            }
            else {
              this.disableButton = false;
              const mess = 'Esta referencia ya se encuentra aprobada';
              this.errorLogin(mess);
            }
          },
          error => {
            this.disableButton = false;
            const mess = 'No se ha encontrado la transaccion';
            this.errorLogin(mess);
          }
        );
      }
      this.loadingScreenService.stopLoading();
      this.loading = true;
    }
    else {
      this.epaycoService.cancelBonus();
      this.loadingScreenService.stopLoading();
      this.loading = true;
      return false;
    }
  }
  async ngAfterViewInit() {
    let request = {
      "reference": this.paymentRequest.data.reference,
      'client': this.paymentRequest.name
    }

    let token = 'Bearer ' + this.auth.token;

    let transactionResponse: any = await this.paymentTransactionService.checkTransactionStatusForNequi(request, token)
      .toPromise();

    if (transactionResponse && transactionResponse.transaction.nequiData) {

      this.loadingScreenService.startLoading();

      let response = transactionResponse.transaction;

      const nequiToken = await this.getTokenNequi();

      let channel = "";

      let retry = 0;

      if (response.method == "nequi_push") {
        channel = "PN";
      }
      if (response.method == "nequi_qr") {
        channel = "QR";
      }
      if (response.method == "nequi_ee") {
        channel = "EE";
      }

      const request = this.encryptService.encryptCipherData({
        reference: response.dev_reference,
        amount: response.amount,
        codeQR: response.nequiData.transactionId,
        email: this.paymentRequest.data.email,
        payment_method: "NEQUI",
        nequi_method: response.method,
        tokenNequi: nequiToken,
        channel: channel,
        subclient: this.paymentRequest.data.subclient ?? null,
        isUnregistredPayment: false
      });

      const verifyPayment: any = await this.nequiService.verifyPayment(request, token).toPromise().catch((error) => {
        return false;
      });

      this.loadingScreenService.stopLoading();

      if (verifyPayment.status == NequiStatus.APPROVED) {
        this.loadingScreenService.stopLoading();
        this._router.navigate(["/pagos/respuesta"], {
          state: {
            paymentRequest: this.paymentRequest,
            response: {
              transaction: {
                status: verifyPayment.status,
                nequi: {
                  code: verifyPayment.code,
                  amount: this.paymentRequest.data.amount,
                  transactionID: verifyPayment ? verifyPayment.transactionID : null,
                  paymentDate: verifyPayment ? verifyPayment.paymentDate : null,
                }
              },
            },
          },
        });
      }

      if (verifyPayment.status == NequiStatus.PENDING) {
        this.nequiService.nequiPending(true);
        Swal.fire({
          title: 'Información',
          text: "Tienes un pago pendiente en Nequi, desde la aplicación \n podrás aprobarlo o cancelarlo si deseas utilizar otro medio de pago.",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-custom-primary'
          },
          confirmButtonText: 'Aceptar',
        })
      }

      if (verifyPayment.status == NequiStatus.TIMEOUT) {
        while(retry < 2){
            const verifyPayment2: any = await this.nequiService.verifyPayment(request, token).toPromise().catch((error) => {
            retry ++;
            if(verifyPayment2.status != NequiStatus.PENDING) this.nequiService.nequiPending(false);
            return false;
          });
          if(verifyPayment2.status != null || verifyPayment2.status != NequiStatus.TIMEOUT) {
            this.nequiService.nequiPending(false);
            break;
          }
        }
      }

    }

    if (this.flag) {
      let davipuntosPanel = document.getElementById('davipuntosPanel');
      davipuntosPanel.scrollIntoView();
    }

    if(this.pendingNequiPayment == true){
      let nequiStep = document.getElementById('nequiStep');
      nequiStep.scrollIntoView(true);
    }
  }

  ngOnInit() {

    this.davipuntosCredentials = this.sessionStorageService.getItem(
      SessionStorageService.DAVIPUNTOS_CREDENTIALS
    );
    this.flag = this.davipuntosCredentials != null;
    if (this.paymentRequest.productsAnalyticsObject) {
      this.sessionStorageService.setItem(SessionStorageService.PRODUCTSANALYTICS, this.paymentRequest.productsAnalyticsObject);
    }
    // scrollTop: $("#elementtoScrollToID").offset().top
    this.enabledPaymentMethods = {
      card: false,
      pse: false,
      mixed_purchase: false
    };

    this.daviplata = this.paymentRequest.is_daviplata;
    this.gateway = this.paymentRequest.gateway[0].payment_method;
    // get value of has_davipuntos_tc
    this.hasDavipuntosTC = this.paymentRequest.has_davipuntos_tc;
    this.isDebit = this.paymentRequest.is_debit != null ? this.paymentRequest.is_debit : true;
    // if(this.paymentRequest.mixed_purchase_data && this.paymentRequest.mixed_purchase_data.no_payments > 0) this.onePayMixetPayment = true;
    // this.enabledPaymentMethods.mixed_purchase = !!this.paymentRequest.mixed_purchase_data;
    this.gateway.forEach(method => {
      if (method.code == "CARD") {
        this.enabledPaymentMethods.card = true;
      }
      if (method.code == "BANK_TRANSACTION") {
        this.enabledPaymentMethods.pse = true;
      }
      // if (method.code == "MIXED_PURCHASE") {
      //   this.enabledPaymentMethods.mixed_purchase = true;
      // }
    });

    if (this.paymentRequest.data.plan) {
      this.method = '1';
    }
    this.paymentRequest.aux_init_amount = this.paymentRequest.data.amount;

    if (this.paymentRequest.data.mixed_purchase) {
      this.mixedPurchaseValidate = true;
    }

    if (this.paymentRequest.hasPCRedemption) {
      this.payWithPointsColombia = this.paymentRequest.hasPCRedemption;
    }


    if (this.paymentRequest.data.amount >= 5000) {
      this.panelDaviPuntosDisabled = false;
    }

    this.epaycoService.savedCardExpanded.subscribe(status => {
      this.toggleAccordion();
    });

    this.epaycoService.cardBinDebit.subscribe(cardNum => {
      if (cardNum != null && cardNum.length >= 6) {
        this.cardBinDebit = cardNum;
      } else {
        this.cardBinDebit = null;
      }
    });

    this.epaycoService.cardBin.subscribe(cardNum => {
      if (cardNum != null && cardNum.length >= 6) {
        this.cardBin = cardNum;
      } else {
        this.cardBin = null;
      }
    });
    this.epaycoService.validatedRetentions.subscribe(retentions => {
      if (this.paymentRequest.retentions && !this.paymentRequest.retentions.message) {
        this.validatorRetentions = retentions;
      }

      if (this.validatorRetentions) {
        this.paymentRequest.data.amount = this.paymentRequest.retentions.total_amount;
      }
    });
    this.datalayerService.addToDataLayerCheckoutEvent('', this.paymentRequest);

    this.nequiService.nequiPendingEmitter.subscribe(res => {
      this.pendingNequiPayment = res;
    })
    if(this.pendingNequiPayment == true){
      this.validateChip("NEQUI")
    }
  }

  errorLogin(message: any) {
    this._snackBar.openFromComponent(SnackMessageComponent, {
      data: message,
      duration: 5000,
      panelClass: ['snackError', 'withMin']
    });
  }

  help(type) {
    switch (type) {
      case 'card':
        this.openCardHelp('credit');
        break;
      case 'card-debit':
        this.openCardHelp('debit');
        break;
      case 'pse':
        this.openPseHelp();
        break;

      default:
        break;
    }
  }

  openCardHelp(card: string) {
    let title = '';
    let message = '';
    let button = '';
    let debit = false;
    if (card == 'credit') {
      title = this.paymentRequest.dialogs.card_help.title;
      message = this.paymentRequest.dialogs.card_help.body;
      button = this.paymentRequest.dialogs.card_help.button;
    } else {
      title = this.paymentRequest.dialogs.card_debit_help.title;
      message = this.paymentRequest.dialogs.card_debit_help.body;
      button = this.paymentRequest.dialogs.card_debit_help.button;
      debit = true;
    }

    this.dialog.open(DefaultDialogComponent, {
      width: '456px',
      minHeight: '200px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: {
        type: 'card',
        paymentRequest: this.paymentRequest,
        title: title,
        message: message,
        defaultBtnText: button,
        debit: debit
      }
    });

  }

  openPseHelp() {
    this.dialog.open(DefaultDialogComponent, {
      width: '456px',
      minHeight: '200px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: {
        type: 'pse',
        paymentRequest: this.paymentRequest,
        title: this.paymentRequest.dialogs.pse_help.title,
        message: this.paymentRequest.dialogs.pse_help.body,
        defaultBtnText: this.paymentRequest.dialogs.pse_help.button
      }
    });
  }

  changeDefaultTokenCard(idCard) {
    this.selectedCard = idCard;
  }

  loadCustomerCards() {
    const data = this.paymentRequest.data;
    data.client = this.paymentRequest.name;
    const auth = this.sessionStorage.getItem<AuthModel>(SessionStorageService.AUTH);
    this.epaycoService.getCustomerCards(auth.token, data).subscribe(
      success => {
        this.cards = Object.values(success);
        this.cardsService.emitConfig(this.cards);
      },
      failure => {
        console.log(failure);
      }
    );
  }

  reloadCards() {
    this.loadingCards = true;
    this.loadCustomerCards();
  }

  async validateChip(paymentMethod = null) {
    this.isOpenTC.next(paymentMethod === 'TC' || paymentMethod === 'TD');

    this.#service.showInfo = false;

    console.log("paymentMethod", paymentMethod);

    // bonos davipuntos
    //(opened)="validateChip()"
    // if ((paymentMethod == 'Checkout' || paymentMethod == 'PSE' || paymentMethod == 'Daviplata' ) && this.paymentRequest.retentions){
    this.epaycoService.validatedRetentions.emit(this.paymentRequest.retentions && paymentMethod !== 'TC');
    // else{
    //   this.loadingScreenService.stopLoading();
    //   this.epaycoService.validatedRetentions.emit(true);
    // }
    this.epaycoService.changeAmountInitial.emit(this.paymentRequest.retentions ? this.paymentRequest.retentions.amount : this.paymentRequest.data.amount);
    this.epaycoService.activateChip.emit(this.paymentRequest.manual_bonus ? true : false);
    this.epaycoService.acordionCardsTokenize.emit(false);
    if (this.paymentRequest.data.origen != "carrito") {
      this.epaycoService.showManualBonus.emit(true);
    }

    if (this.paymentRequest.data.mixed_purchase) {
      this.epaycoService.activateMixed.emit(false);
    }
  }

  mixedPurchase() {
    this.epaycoService.activateMixed.emit(true);
  }

  //Este fix es la cosa mas idiota que he visto en mi vida
  //pero por lo menos funciona
  toggleAccordion() {
    this.accordion.multi = true;
    this.accordion.closeAll();
    this.accordion.multi = false;
  }

  selectCard(debit?) {
    let validateCardBin;
    validateCardBin = debit == true ? this.cardBinDebit : this.cardBin;
    if ((validateCardBin != null || validateCardBin > 5) && this.paymentRequest.data.client_token) {
      this.consumeBonusBines(validateCardBin);
    }
    if (!debit) {
      this.epaycoService.validatedRetentions.emit(false);
    }

    this.epaycoService.validatedRetentions.emit(this.paymentRequest.retentions && debit);
  }

  closedCard() {
    this.epaycoService.validatedRetentions.emit(false);
    this.epaycoService.activateChip.emit(false);
    this.epaycoService.showManualBonus.emit(false);
  }

  consumeBonusBines(cardNum) {
    let auth = this.sessionStorageService.getItem<LoginModel>(SessionStorageService.AUTH);
    this.loadingScreenService.startLoading();
    let request = {
      card_number: this.encryptService.encryptStringData(cardNum),
      token_external: this.paymentRequest.data.client_token,
      reference: this.paymentRequest.data.reference
    };

    this.paymentTransactionService.consumeBonusBines(request, auth.token).subscribe(
      res => {
        this.loadingScreenService.stopLoading();
        if (res.has_discount) {
          this.paymentRequest.data.amount = res.amount;
          this.paymentRequest.aplly_bonus = true;
          this.paymentRequest.card_number = request.card_number;
          this.paymentRequest.voucher_name = res.voucher_name;
          this.epaycoService.activateChip.emit(true);
          this.paymentTransactionService.displayBonusSnack(res.dialog.body);
        } else {
          this.epaycoService.activateChip.emit(false);
          //this.service.changeAmountInitial.emit(this.paymentRequest.aux_init_amount);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  expandCheckoutPanel() {
    if (this.disableDavipuntos()) {
      return false;
    }
    if (!this.panelDaviPuntosDisabled) {
      this.pnlCheckoutEpayco.toggle();
    }
  }

  public AddInfoDataLayer($medioDePago, $step) {

    this.datalayerService.AddInfoDataLayerPaymentMethod($medioDePago);

    nameCategory = this.paymentRequest.name;
    window.dataLayer.push({
      eventCategory: nameCategory + "-Portal de pagos",
      eventAction: "Selección medio de pago",
      eventLabel: $medioDePago,
      eventValue: '',
      event: 'eventClick'
    });
  }

  private validarBono(paymentRequest) {
    return new Promise<any>((resolve, reject) => {
      this.loadingScreenService.startLoading();
      this.auth = this.sessionStorageService.getItem<AuthModel>(SessionStorageService.AUTH);
      const req = {
        client: paymentRequest.name,
        amount: paymentRequest.aux_init_amount,
        dev_reference: paymentRequest.data.reference,
        bonus_voucher: paymentRequest.manual_bonus_voucher,
        id_type: paymentRequest.data.id_type,
        vat: paymentRequest.data.vat,
        rete_ica: paymentRequest.data.rete_ica,
        rete_iva: paymentRequest.data.rete_iva,
        rete_fuente: paymentRequest.data.rete_fuente,
        // id_product : paymentRequest.data.id_producto,
        external_user_id: paymentRequest.data.external_user_id,
        token_external: paymentRequest.data.token_external,
        retentions: paymentRequest.data.retentions,
        name: paymentRequest.data.name,
        last_name: paymentRequest.data.last_name,
        email: paymentRequest.data.email,
        phone: paymentRequest.data.phone,
        manual_bonus: true,
        external_user_doc: paymentRequest.data.id,
        external_user_email: paymentRequest.data.email,
        products: paymentRequest.data.products ? paymentRequest.data.products : null
      }
      if (paymentRequest.manual_bonus_voucher) {
        this.epaycoService.validatedRetentions.subscribe(retentions => {
          this.validatorRetentions = retentions && !retentions.message;
        })
        this.validateBonusService.validBonus(req, "Bearer " + this.auth.token).subscribe(res => {
          if (res.bonus.code == 200) {
            paymentRequest.retentions = res.retentions;
            this.epaycoService.activateChip.emit(true);

            if (this.validatorRetentions) {
              paymentRequest.data.amount = res.retentions ? res.retentions.total_amount : res.bonus.result.total;
            }
            else {
              paymentRequest.data.amount = res.bonus.result.total;
            }
            paymentRequest.aplly_bonus = true;
            paymentRequest.voucher_name = res.bonus.result.type_discount;
            paymentRequest.manual_bonus = true;
            resolve(true);
          } else {
            Swal.fire({
              title: 'Bono expirado',
              text: 'Este bono ya expiró, por favor intente con uno nuevo o realice el pago total de la transacción',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-custom-primary'
              },
              confirmButtonText: 'Confirmar'
            })
            this.loadingScreenService.stopLoading();
            resolve(false);
          }
        })
      }
      else {

        resolve(false);
      }

    })
  }

  async getTokenNequi() {
    let tokenNequi = '';
    const token = 'Bearer ' + this.auth.token;

    try {
      tokenNequi = this.sessionStorageService.getItem(SessionStorageService.NEQUI_TOKEN);

      if (!tokenNequi) {
        const subclientNequi = this.paymentRequest.data.subclient ?? null;
        const response = await this.nequiService.getToken(this.paymentRequest.data.reference, subclientNequi, token).toPromise();

        tokenNequi = this.authService.decryptDataPlain(response.token);
        this.sessionStorageService.setItem(SessionStorageService.NEQUI_TOKEN, tokenNequi);
      }

      return tokenNequi;
    }

    catch (error) {

    }
  }

  addToDataLayerCheckoutEvent(method: string, paymentRequest) {
    this.datalayerService.addToDataLayerCheckoutEvent(method, paymentRequest);
  }

  disabledPaymentMethod() {
    // Deshabilitar metodos de pago en general
    if (this.pendingNequiPayment) {
      return true;
    }

    if (this.paymentRequest.data.requires_split_payment == true && this.paymentRequest.data.dataSplitTransactions.length) {
      if (this.paymentRequest.data.subclient) {
        return false;
      }
      return true;
    }
    return false;
  }

  disableDavipuntos() {
    if (this.panelDaviPuntosDisabled) {
      return true;
    }
    if (this.pendingNequiPayment) {
      return true;
    }
    if (this.paymentRequest.data.requires_split_payment == true && this.paymentRequest.data.dataSplitTransactions.length) {
      if (this.paymentRequest.data.subclient) {
        return false;
      }
      return true;
    }
    return false;
  }

  hideBankTransferFromEpayco() {
// Se desactiva pse de epayco, cuando viene activado pago dividido de nuvei
//     if (this.paymentRequest.gateway_active != 'Paymentez') {
    if (this.paymentRequest.data.requires_split_payment == true && this.paymentRequest.data.dataSplitTransactions.length) {
      if (this.paymentRequest.data.subclient) {
        return false;
      }
      return true;
    }
    // }

    if ((this.enabledPaymentMethods && this.enabledPaymentMethods.pse && !this.paymentRequest.data.plan) ||
      (this.enabledPaymentMethods && this.enabledPaymentMethods.pse && this.paymentRequest.data.plan && this.paymentRequest.data.pay_link)) {
      return false;
    }
    return true;
  }

  hideBankTransferFromNuvei() {
    // Se muestra PSE para los portales en general
    if (!this.enabledPaymentMethods && !this.enabledPaymentMethods.pse) {
      return true;
    }
    if (this.pendingNequiPayment) {
      return true;
    }
    if (this.paymentRequest.data.requires_split_payment == true && this.paymentRequest.data.dataSplitTransactions.length) {
      if (this.paymentRequest.data.subclient) {
        return true;
      }
      return false;
    }
    return true;
  }

}
