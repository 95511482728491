import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PaymentResponseComponent } from '../payment/payment-response/payment-response.component';
import { MatDialog } from "@angular/material/dialog";
import { ReturnBrowserComponent } from "../return-browser/return-browser.component";

@Injectable({
  providedIn: 'root'
})
export class ReturnBrowserGuard implements CanActivate, CanDeactivate<unknown> {

  constructor(public dialog: MatDialog) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canDeactivate(
    component: PaymentResponseComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const clientName = component.paymentRequest.name;

    if (clientName == 'segurosbolivar-comercial' || clientName == 'segurosbolivar') {

      const modal = this.dialog.open(ReturnBrowserComponent, {
        width: '100%',
        height: '100%',
        maxWidth: '100%',
      });

      const orderPaymentezModel = component.paymentRequest.data;

      modal.afterClosed().subscribe(result => {
        if (result) {
          window.location.href = orderPaymentezModel.url_return;
        }
      });
      return modal.afterClosed();
    }

    return true;
  }

}
